import Swiper from "swiper";
import { Autoplay, Navigation, Pagination, Keyboard, EffectFade, Scrollbar, EffectCoverflow, Controller, Thumbs } from "swiper/modules";
export const slider = () => {
    if (document.querySelector('.slider')) {
        const sliders = document.querySelectorAll('.sliderSimple')
        sliders.forEach(slider => {
            const sliderWrapper = slider.querySelector(".swiper-wrapper");
            let sliderItems = slider.querySelectorAll(".swiper-slide");
            if (sliderWrapper && sliderItems.length > 0) {

                let slidesCount = sliderItems.length;

                if (slidesCount <= 8) {
                    let repeat = Math.ceil((8 / slidesCount) * 2);
                    if (repeat > 0) {
                        for (let i = 0, n = repeat; i < n; i++) {
                            sliderItems.forEach((item) => {
                                const swiperItemNew = document.createElement("div");
                                swiperItemNew.classList.add("swiper-slide");
                                swiperItemNew.innerHTML = item.innerHTML;
                                sliderWrapper.append(swiperItemNew);
                            });
                        }
                    }
                }

                const swiper = new Swiper(slider, {
                    modules: [Autoplay],
                    loop: true,
                    centeredSlides: false,
                    slidesPerView: "auto",
                    speed: 8000,
                    allowTouchMove: false,
                    autoplay: {
                        delay: 0,
                        disableOnInteraction: false,
                    },
                });
            }
        })

    }

    if (document.querySelector('.tech')) {
        const slider = document.querySelector('.swiperTech')
        if (slider) {
            const swiper = new Swiper(slider, {
                modules: [Navigation],
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  },
            });
        }

    }
}
