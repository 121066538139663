import { burger } from "./modules/header.js";
import { spline } from "./modules/spline.js";
import { slider } from "./modules/slider.js";
import { tabs } from "./modules/tabs.js";
import { map } from "./modules/map.js";
import { form } from "./modules/form.js";
import { preloader } from "./modules/preloader.js";

document.addEventListener("DOMContentLoaded", (event) => {
    //the scripts you need on the first screen.
    preloader()

    let scriptLaunchStart = true;
    //this function is called once, after a user interaction.
    const scriptLaunch = () => {
        /*the rest of the scripts, for example:
         * working with bibilio libraries, sliders, etc.
         */

        burger()
        spline()
        slider()
        tabs()
        map()
        form()

    }
    if (window.pageYOffset >= 1 && scriptLaunchStart) {
        scriptLaunchStart = false;
        scriptLaunch();
    } else if (window.pageYOffset < 1 && scriptLaunchStart) {
        const events = ['mousemove', 'click', 'mousewheel', 'scroll', 'touchstart', 'keydown', 'keypress', 'mouseover', 'mouseout', 'resize'];
        events.forEach(event => {
            window.addEventListener(event, () => {
                if (scriptLaunchStart) {
                    scriptLaunchStart = false;
                    scriptLaunch();
                }
            });
        });
    }
});