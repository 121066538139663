export const preloader = () => {
    const preloader = document.querySelector('.preloader');
    const logos = preloader.querySelector('.preloader__logos');

    if (preloader) {
        const counters = document.querySelectorAll('.preloader__count');

        preloader.classList.add('active');

        // Function to calculate the total height based on the span height
        const calculateHeight = (maxValue, spanHeight) => {
            return maxValue * spanHeight; // Total height to stop at the last number
        };

        // Function to set the animation for each counter
        const setCounterAnimation = () => {
            counters.forEach(counter => {
                const numberWrapper = counter.querySelector('.number-wrapper');
                const maxValue = parseInt(counter.getAttribute('aria-label'), 10);
                numberWrapper.innerHTML = '';
                
                // Clear existing spans
                for (let i = 0; i <= maxValue; i++) {
                    const span = document.createElement('span');
                    span.textContent = i;
                    numberWrapper.appendChild(span);
                }

                // Adjust the span height dynamically based on screen size
                let spanHeight;
                if (window.innerWidth <= 500) {
                    spanHeight = 70; // Height for small screens
                } else if (window.innerWidth <= 768) {
                    spanHeight = 100; // Height for medium screens
                } else {
                    spanHeight = 171; // Default height for large screens
                }

                const totalHeight = calculateHeight(maxValue, spanHeight);
                numberWrapper.style.setProperty('--totalHeight', `${totalHeight}px`);
                numberWrapper.style.animationDuration = '2s';
            });
        };

        // Initial call to set animation
        setCounterAnimation();

        // Recalculate and set animation on window resize
        window.addEventListener('resize', setCounterAnimation);
    }

    setTimeout(() => {
        preloader.classList.add('hidden');
        logos.classList.add('hidden');
    }, 2500);
};
