export const tabs = () => {
    const tabs = document.querySelector('.tabs')
    if (tabs) {
        const tabsBody = document.querySelectorAll('.tabs__body')
        const titles = document.querySelectorAll('.tabs__button')
        titles[0].classList.add('active');
        tabsBody[0].classList.add('active');
        titles.forEach((title, i) => {
            title.addEventListener('click', () => {
                titles.forEach(item => {
                    item.classList.remove('active')
                })
                tabsBody.forEach(item => {
                    item.classList.remove('active')
                })
                title.classList.add('active')
                tabsBody[i].classList.add('active')
            })
        })
    }
}
