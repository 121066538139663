export const burger = () => {
    AOS.init();

    //== Start add burger-menu ==//
    const header = document.querySelector('.header')
    const burgerBtn = document.querySelector('.header__burger')
    const menu = document.querySelector('.header__wp')
    const menuItems = menu.querySelectorAll('a')
    const html = document.querySelector('html')

    menuItems.forEach(item => {
        item.addEventListener('click', () => {
            menu.classList.remove('open')
            header.classList.remove('open')
            burgerBtn.classList.remove('open')
            changeHtmlOverflow('remove')
        })
    })

    burgerBtn.addEventListener('click', () => {
        menu.classList.toggle('open')
        header.classList.toggle('open')
        burgerBtn.classList.toggle('open')

        if (menu.classList.contains('open')) {
            changeHtmlOverflow('add')
        } else {
            changeHtmlOverflow('remove')
        }
    })
    //== End add burger-menu ==//


    const changeHtmlOverflow = (action) => {
        if (action === 'add') {
            html.style.overflow = 'hidden'
        }
        if (action === 'remove') {
            html.style.overflow = 'inherit'
        }
    }

};