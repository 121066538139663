import { Application } from '@splinetool/runtime';

export const spline = () => {
    const hero = document.querySelector('.hero')
    const heroTeam = document.querySelector('.hero-team')
    const canvas = document.getElementById('canvas3d');

    if (hero && canvas) {
        const splineUrl = hero.querySelector('#spline__url')
        const app = new Application(canvas);
        app.load(splineUrl.textContent);
    }
    if (heroTeam && canvas) {
        const splineUrl = heroTeam.querySelector('#spline__url')
        const app = new Application(canvas);
        app.load(splineUrl.textContent);
    }
};