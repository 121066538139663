export const form = () => {
    if (document.querySelector('.contact')) {
        const send = document.querySelector(".contact__form-button")
        const popup = document.querySelector('.form__popup-wrapper')
        const close = document.querySelector('.form__popup-close')
        close.addEventListener('click', () => {
            popup.classList.remove('active')
        });


        //== start validation ==//
        const checkboxWrappers = document.querySelectorAll('.contact__form-checkbox')
        checkboxWrappers.forEach(checkboxWrapper => {
            const checkboxes = checkboxWrapper.querySelectorAll('input')
            checkboxes.forEach(checkbox => {
                checkbox.addEventListener('click', function () {
                    if (this.checked) {
                        checkboxes.forEach(otherCheckbox => {
                            if (otherCheckbox !== this) {
                                otherCheckbox.checked = false;
                            }
                        });
                    } else {
                        this.checked = true;
                    }
                });
            });
        })


        function validateEmail(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        const getError = (message, error, input) => {
            message.textContent = "Fields are not filled in correctly"
            error.classList.add("active")
            input.classList.add("error")
        }

        const inputs = document.querySelectorAll(".contact__input")
        inputs.length && inputs.forEach(input => {
            input.addEventListener('input', () => {
                const wrapper = input.parentElement
                const error = wrapper.querySelector('.contact__error')
                if (input.value) {
                    input.classList.remove("error")
                    error.classList.remove('active')
                } else {
                    error.classList.add('active')
                    input.classList.add("error")
                }
            })
        })

        function send_lava() {
            let isError = false
            const message = document.querySelector(".contact__message")
            message.textContent = ""
            const form = document.querySelector(".contact__form")
            const name = form.querySelector(".name")
            const email = form.querySelector(".email")
            const company = form.querySelector(".company")
            const information = form.querySelector(".information")
            const howHear = form.querySelector(".how_hear")
            const cells = document.querySelectorAll(".contact__require")
            cells.forEach(cell => {

                const input = cell.querySelector(".contact__input")
                const error = cell.querySelector(".contact__error")
                error.classList.remove("active")
                input.classList.remove("error")
                if (!input.value || input.value === " ") {
                    getError(message, error, input)
                    isError = true

                    error.textContent = "Error field"

                } else {
                    if (input.classList.contains("email") && !validateEmail(email.value)) {
                        getError(message, error, input)
                        isError = true
                        error.textContent = "Invalid format"
                    }
                }
            });
            const selectedCheckboxes = {};
            checkboxWrappers.forEach((checkboxWrapper, index) => {
                const checkError = checkboxWrapper.querySelector(".contact__error")
                const checkboxes = checkboxWrapper.querySelectorAll('input');
                updateSelectedCheckboxes(index, checkboxes);
                checkboxes.forEach(checkbox => {
                    const isAnyChecked = Array.from(checkboxes).some(cb => cb.checked);
                    if (isAnyChecked) {
                        checkError.classList.remove('active');
                    } else {
                        checkError.classList.add('active');
                    }

                    checkbox.addEventListener('change', () => {
                        updateSelectedCheckboxes(index, checkboxes);


                        const isAnyChecked = Array.from(checkboxes).some(cb => cb.checked);

                        if (isAnyChecked) {
                            checkError.classList.remove('active');
                        } else {
                            checkError.classList.add('active');
                        }

                    });
                })
            })
            function updateSelectedCheckboxes(wrapperIndex, checkboxes) {
                const checkedValues = Array.from(checkboxes)
                    .filter(cb => cb.checked)
                    .map(cb => cb.closest('label').innerText.trim());

                selectedCheckboxes[`checkboxWrapper_${wrapperIndex}`] = checkedValues;
            }

            const data = {
                "action": "lava",
                "name": name.value,
                "email": email.value,
                "company": company.value,
                "info": information.value,
                "how": howHear.value,
                ...selectedCheckboxes
            }

            if (!isError) {
                $.ajax({
                    url: "/wp-admin/admin-ajax.php",
                    data: data,
                    type: "POST",
                    success: function (data) {
                        if (data) {
                            message.textContent = "Message sent successfully"
                            popup.classList.add('active')
                        }
                    },
                });
            }

        }


        send && send.addEventListener("click", () => {
            send_lava()
        })
        //== end validation ==//

        // const formData = {
        //     fields: [
        //         {
        //             name: "email",
        //             value: "test@example.com"  
        //         },
        //         {
        //             name: "firstname",
        //             value: "Test" 
        //         },
        //         {
        //             name: "lastname",
        //             value: "User" 
        //         }
        //     ],
        //     context: {
        //         pageUri: window.location.href,
        //         pageName: document.title
        //     }
        // };

        // const portalId = "144670705";
        // const formId = "7078139d-5d51-41c5-8776-fb17c2d05c9e";

        // fetch(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
        //     method: "POST",
        //     headers: {
        //         "Content-Type": "application/json"
        //     },
        //     body: JSON.stringify(formData)
        // })
        // .then(response => response.json())
        // .then(data => {
        //     console.log("Success:", data);
        // })
        // .catch(error => {
        //     console.error("Error:", error);
        // });


    }
}