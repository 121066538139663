export const map = () => {
    const mapWrapper = document.querySelector('.map__wrapper')
    if (mapWrapper) {
        const label = mapWrapper.querySelector('.map__label')
        const countrys = document.querySelectorAll('.country')
        countrys.forEach(country => {
            const name = country.getAttribute('data-name')
            country.addEventListener('mousemove', (event) => {
                label.classList.add('active')
                const ellipse = country.parentElement.querySelector('ellipse')
                const labelRect = label.getBoundingClientRect();
                const ellipseRect = ellipse.getBoundingClientRect();

                label.textContent = name;
                label.style.left = `${ellipseRect.left + window.scrollX - (labelRect.width / 2) + 4}px`;
                label.style.top = `${ellipseRect.top + window.scrollY - 24}px`;
            })

            country.addEventListener('mouseleave', () => {
                label.classList.remove('active');
            });
        })



    }
};